import React, { useState, useEffect } from "react";
import Link from "next/link";
import { useRouter } from "next/router";
import styled from "@emotion/styled";
import { useUser } from "@context/userContext";
import { getPendingCounts } from "@context/commentsContext";
import { useMenuAccess } from "@context/MenuContext";

const Box = styled.section`
  position: fixed;
  height: 100vh;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: 30;
  width:  ${(props) => (props.menuDynamic ? "70px" : "240px")};
  background-color: #263238;
  color: #ffffff;
  transition: all 0.3s;
  @media (max-width: 768px) {
    left: ${(props) => (props.menuDynamic ? "-70px" : "0px")};
  }
`;

const BoxTitle = styled.div`
  width: 100%;
  height: ${(props) => (props.menuDynamic ? "80px" : "10px")};
  display: grid;
  place-content: center;
  padding-bottom: 5px;
  position: relative;
`;

const ContainerBoxImage = styled.div`
  display: ${(props) => (props.menuDynamic ? "none" : "block")};
  position: relative;
  height: 70px;
  border-left: 8px solid #263238;
  border-right: 8px solid #263238;
`;

const BoxLogoResponsive = styled.img`
  width: 50px;
  height: 50px;
  border-radius: 37px;
  display: block;
  background: white;
  object-fit: cover;
`;

const BoxLogoResponsive2 = styled.img`
  width: 70px;
  height: 70px;
  border-radius: 37px;
  display: block;
  background: white;
  object-fit: cover;
`;

const BoxMenu = styled.div`
  width: 100%;
  height: 100%;
  position: absolute;
`;

const BoxUl = styled.ul`
  overflow: auto;
  height: 100%;
  ::-webkit-scrollbar {
    width: 0px;
    height: 5px;
  }
  ::-webkit-scrollbar-thumb {
    background: gray;
    border-radius: 4px;
  }
  ::-webkit-scrollbar-thumb:active {
    background-color: gray;
  }
  ::-webkit-scrollbar-thumb:hover {
    background: gray;
    box-shadow: 0 0 2px 1px rgba(0, 0, 0, 0.2);
  }
  ::-webkit-scrollbar-track {
    background: #e1e1e1;
    border-radius: 4px;
  }
  ::-webkit-scrollbar-track:hover,
  ::-webkit-scrollbar-track:active {
    background: #d4d4d4;
  }
`;

const BoxLink = styled.a`
  display: block;
  padding: 0 18px;
  height: 50px;
  line-height: 50px;
  text-decoration: none;
  letter-spacing: 0.0125em;
  color: white;
  overflow: hidden;
  z-index: -1000;
  cursor: pointer;
  width: 95%;
  &:hover {
    background-color: black;
    border-top-right-radius: 20px;
    border-bottom-right-radius: 20px;
    color: white !important;
  }
`;

const TitleMenu = styled.div`
  display: ${(props) => (props.menuDynamic ? "none" : "block")};
  padding: 19.2px 16px;
  font-size: 0.12.8px;
  font-weight: 700;
  margin-left: -5px;
  letter-spacing: 0.72px;
  margin-top: 50px;
`;

const SpanLink = styled.span`
  position: relative;
  display: inline-block;
  width: 24px;
  height: 24px;
  margin-right: 30px;
  text-align: center;
  font-size: 24px;
  vertical-align: middle;
  background-image: url(${(props) => `/statics/img/icon_menu/${props.image}`});
  background-size: cover;
  filter: ${(props) => (props.invertImage ? "invert(0%)" : "invert(100%)")};
`;

const BadgeBox = styled.span`
  min-width: 22px;
  height: 22px;
  text-align: center;
  font-size: 12px;
  font-weight: 700;
  border-radius: 50%;
  background-color: #ff0000;
  color: #ffffff;
  padding: 2px;
  margin-left: 8px;
  display: inline-block;
  vertical-align: middle;
  line-height: 1.4;
`;

const ContainerAvatar = styled('div')`
  display: ${(props) => (props.menuDynamic ? "none" : "block")};
  height: 100px;
  display: flex;
  align-items: center;
  border-radius: 10px;
  flex-direction: row;
  background-image: url('/statics/img/shopFont.jpg');
  background-size: cover;
  position: relative;
  color: white;
  padding-left: 5px;
  & img {
    border-radius: 50%;
    object-fit: cover;
  }
`;

const months = ['Enero', 'Febrero', 'Marzo', 'Abril', 'Mayo', 'Junio', 'Julio', 'Agosto', 'Septiembre', 'Octubre', 'Noviembre', 'Diciembre'];

const SideNav = ({ menuView, role, menuDynamic }) => {
  const { user } = useUser();
  const { accessMenus } = useMenuAccess();
  const [namePage, setNamePage] = useState('principal')
  const { pendingComments, offerCount, couponCount } = getPendingCounts();
  // const pendingComments = getPendingComments();
  const router = useRouter();
  const currentDate = new Date();
  const day = currentDate.getDate();
  const month = currentDate.getMonth();
  const year = currentDate.getFullYear();
  const formattedDate = `${day} de ${months[month]} de ${year}`;

  const verifiedMenu = (name, id) => {
    const findMenu = accessMenus.find(({ name: menuName }) => menuName === name);
    return findMenu && findMenu.access.includes(id.toString());
  };

  const handleclick = () => {
    if(window.innerWidth < 768){
      menuView()
    }
  }
  console.log(pendingComments);
  useEffect(() => {
    const panelFromUrl = router.pathname.split('/')[2];
    if (router.pathname === '/') {
      setNamePage('principal');
    } else {
      setNamePage(panelFromUrl);
    }
  }, [router.pathname]);

  return (
    <Box menuDynamic={menuDynamic}>
      <BoxTitle menuDynamic={menuDynamic}>
        {menuDynamic ?
          <div>
            <BoxLogoResponsive className="rounded" src={user.avatar ? user.avatar : "/statics/img/512x512.png"} alt="Profile" />
            <div className="m-0 text-center"><strong>Menu</strong></div>
          </div> : null
        }
      </BoxTitle>
      <BoxMenu menuDynamic={menuDynamic}>
        <ContainerBoxImage menuDynamic={menuDynamic}>
          <ContainerAvatar menuDynamic={menuDynamic}>
            <div style={{ borderRadius: '50%', padding: '0px', background: 'none', border: '3px solid transparent' }}>
              <BoxLogoResponsive2 src={user.avatar ? user.avatar : '/statics/img/user.svg'} alt={'avatar en menu'} width={70} height={70} />
            </div>
            <div className="mt-2 pl-4" style={{ width: '65%'}}>
              <p className="fw-bold h4 text-end pe-3">{user.username}</p>
              <p className="h6 text-end pe-3">{user.email}</p>
            </div>
          </ContainerAvatar>
          <h2 className="text-center h3 mt-3 mb-3">{formattedDate ?? ''}</h2>
        </ContainerBoxImage>
        <TitleMenu menuDynamic={menuDynamic}>Menu</TitleMenu>
        <BoxUl>
          <li>
            <Link href="https://www.ofertu.co/" passHref>
              <BoxLink href="Pagina Ofertu Principal">
                <SpanLink image="192x192.png" invertImage={true}></SpanLink>
                <span> Ir a Ofertu.co</span>
              </BoxLink>
            </Link>
          </li>
          <li>
            <Link href="/" passHref>
              <BoxLink href="Menu Principal" onClick={handleclick} style={namePage === 'principal' ? {background: 'linear-gradient(to right top, #3ead47, #56bd41, #70cd38, #8bdc2b, #a8eb12)', borderTopRightRadius: '20px', borderBottomRightRadius: '20px'} : {}}>
                <SpanLink image="libro-abierto.png"></SpanLink>
                <span>Pagina principal</span>
              </BoxLink>
            </Link>
          </li>
          {role === "superadmin" &&
            <li>
              <Link href="/admin/panel" passHref >
                <BoxLink href="Panel Control" onClick={handleclick} style={namePage === 'panel' ? {background: 'linear-gradient(to right top, #3ead47, #56bd41, #70cd38, #8bdc2b, #a8eb12)', borderTopRightRadius: '20px', borderBottomRightRadius: '20px'} : {}}>
                  <SpanLink image="control.png"></SpanLink>
                  <span> Panel de control</span>
                </BoxLink>
              </Link>
            </li>
          }
          {(role === "superadmin" || verifiedMenu("ofertas", user.id)) &&
            <li>
              <Link href="/admin/offers" passHref>
                <BoxLink href="Menu_principal" onClick={handleclick} style={namePage === 'offers' ? {background: 'linear-gradient(to right top, #3ead47, #56bd41, #70cd38, #8bdc2b, #a8eb12)', borderTopRightRadius: '20px', borderBottomRightRadius: '20px'} : {}}>
                  <SpanLink image="etiqueta-de-precio.png"></SpanLink>
                  <span>Ofertas</span>
                  {offerCount > 0 ? <BadgeBox>{offerCount}</BadgeBox> : null}
                </BoxLink>
              </Link>
            </li>
          }
          {(role === "superadmin" || verifiedMenu("cupones", user.id)) &&
            <li>
              <Link href="/admin/coupons" passHref>
                <BoxLink href="Cupones" onClick={handleclick} style={namePage === 'coupons' ? {background: 'linear-gradient(to right top, #3ead47, #56bd41, #70cd38, #8bdc2b, #a8eb12)', borderTopRightRadius: '20px', borderBottomRightRadius: '20px'} : {}}>
                  <SpanLink image="cupon.png"></SpanLink>
                  <span>Cupones</span>
                  {couponCount > 0 ? <BadgeBox>{couponCount}</BadgeBox> : null}
                </BoxLink>
              </Link>
            </li>
          }
          {(role === "superadmin" || verifiedMenu("multicodigos", user.id)) &&
            <li>
              <Link href="/admin/multicode" passHref>
                <BoxLink href="Multicode" onClick={handleclick} style={namePage === 'multicode' ? {background: 'linear-gradient(to right top, #3ead47, #56bd41, #70cd38, #8bdc2b, #a8eb12)', borderTopRightRadius: '20px', borderBottomRightRadius: '20px'} : {}}>
                  <SpanLink image="referral-code.png"></SpanLink>
                  <span>Multicodigos</span>
                </BoxLink>
              </Link>
            </li>
          }
          {(role === "superadmin" || verifiedMenu("comentarios", user.id)) &&
            <li>
              <Link href="/admin/comments" passHref>
                <BoxLink href="Comentarios" onClick={handleclick} style={namePage === 'comments' ? {background: 'linear-gradient(to right top, #3ead47, #56bd41, #70cd38, #8bdc2b, #a8eb12)', borderTopRightRadius: '20px', borderBottomRightRadius: '20px'} : {}}>
                  <SpanLink image="conversacion.png"></SpanLink>
                  <span>Comentarios</span>
                  {pendingComments > 0 ? <BadgeBox>{pendingComments}</BadgeBox> : null}
                </BoxLink>
              </Link>
            </li>
          }
          {(role === "superadmin" || verifiedMenu("tiendas", user.id)) &&
            <li>
              <Link href="/admin/shops" passHref>
                <BoxLink href="Tiendas" onClick={handleclick} style={namePage === 'shops' ? {background: 'linear-gradient(to right top, #3ead47, #56bd41, #70cd38, #8bdc2b, #a8eb12)', borderTopRightRadius: '20px', borderBottomRightRadius: '20px'} : {}}>
                  <SpanLink image="carro.png"></SpanLink>
                  <span>Tiendas</span>
                </BoxLink>
              </Link>
            </li>
          }
          {(role === "superadmin" || verifiedMenu("categorias", user.id)) &&
            <li>
              <Link href="/admin/category" passHref>
                <BoxLink href="Categorias" onClick={handleclick} style={namePage === 'category' ? {background: 'linear-gradient(to right top, #3ead47, #56bd41, #70cd38, #8bdc2b, #a8eb12)', borderTopRightRadius: '20px', borderBottomRightRadius: '20px'} : {}}>
                  <SpanLink image="categorias.png"></SpanLink>
                  <span>Categorias</span>
                </BoxLink>
              </Link>
            </li>
          }
          {(role === "superadmin" || verifiedMenu("crear editor", user.id)) &&
            <li>
              <Link href="/admin/form_editor" passHref>
                <BoxLink href="Crear Editor" onClick={handleclick} style={namePage === 'form_editor' ? {background: 'linear-gradient(to right top, #3ead47, #56bd41, #70cd38, #8bdc2b, #a8eb12)', borderTopRightRadius: '20px', borderBottomRightRadius: '20px'} : {}}>
                  <SpanLink image="usuario.png"></SpanLink>
                  <span>Crear Editor</span>
                </BoxLink>
              </Link>
            </li>
          }
          {(role === "superadmin" || verifiedMenu("crear oferta", user.id)) &&
            <li>
              <Link href="/admin/create_offer" passHref>
                <BoxLink href="Crear Oferta" onClick={handleclick} style={namePage === 'create_offer' ? {background: 'linear-gradient(to right top, #3ead47, #56bd41, #70cd38, #8bdc2b, #a8eb12)', borderTopRightRadius: '20px', borderBottomRightRadius: '20px'} : {}}>
                  <SpanLink image="etiqueta-de-precio.png"></SpanLink>
                  <span>Crear Oferta</span>
                </BoxLink>
              </Link>
            </li>
          }
          {(role === "superadmin" || verifiedMenu("crear cupon", user.id)) &&
            <li>
              <Link href="/admin/create_coupon" passHref>
                <BoxLink href="Crear Cupon" onClick={handleclick} style={namePage === 'create_coupon' ? {background: 'linear-gradient(to right top, #3ead47, #56bd41, #70cd38, #8bdc2b, #a8eb12)', borderTopRightRadius: '20px', borderBottomRightRadius: '20px'} : {}}>
                  <SpanLink image="cupon.png"></SpanLink>
                  <span>Crear Cupon</span>
                </BoxLink>
              </Link>
            </li>
          }
          {(role === "superadmin" || verifiedMenu("usuarios", user.id)) &&
            <li>
              <Link href="/admin/users" passHref>
                <BoxLink href="Usuarios" onClick={handleclick} style={namePage === 'users' ? {background: 'linear-gradient(to right top, #3ead47, #56bd41, #70cd38, #8bdc2b, #a8eb12)', borderTopRightRadius: '20px', borderBottomRightRadius: '20px'} : {}}>
                  <SpanLink image="grupo-de-usuarios.png"></SpanLink>
                  <span>Usuarios</span>
                </BoxLink>
              </Link>
            </li>
          }
          {(role === "superadmin" || verifiedMenu("banners", user.id)) &&
            <li>
              <Link href="/admin/banners" passHref>
                <BoxLink href="Banners" onClick={handleclick} style={namePage === 'banners' ? {background: 'linear-gradient(to right top, #3ead47, #56bd41, #70cd38, #8bdc2b, #a8eb12)', borderTopRightRadius: '20px', borderBottomRightRadius: '20px'} : {}}>
                  <SpanLink image="digital.png"></SpanLink>
                  <span>Banners</span>
                </BoxLink>
              </Link>
            </li>
          }
          {(role === "superadmin" || verifiedMenu("concurso", user.id)) &&
            <li>
              <Link href="/admin/raffle" passHref>
                <BoxLink href="Concurso" onClick={handleclick} style={namePage === 'raffle' ? {background: 'linear-gradient(to right top, #3ead47, #56bd41, #70cd38, #8bdc2b, #a8eb12)', borderTopRightRadius: '20px', borderBottomRightRadius: '20px'} : {}}>
                  <SpanLink image="premio.png"></SpanLink>
                  <span>Concurso</span>
                </BoxLink>
              </Link>
            </li>
          }
          {(role === "superadmin" || verifiedMenu("top tiendas", user.id)) &&
            <li>
              <Link href="/admin/top_shops" passHref>
                <BoxLink href="Top Tiendas" onClick={handleclick} style={namePage === 'top_shops' ? {background: 'linear-gradient(to right top, #3ead47, #56bd41, #70cd38, #8bdc2b, #a8eb12)', borderTopRightRadius: '20px', borderBottomRightRadius: '20px'} : {}}>
                  <SpanLink image="tienda.png"></SpanLink>
                  <span>Top Tiendas</span>
                </BoxLink>
              </Link>
            </li>
          }
          {(role === "superadmin" || verifiedMenu("interacciones", user.id)) &&
            <li>
              <Link href="/admin/users-interactive" passHref>
                <BoxLink href="Interacciones Usuarios" onClick={handleclick} style={namePage === 'users-interactive' ? {background: 'linear-gradient(to right top, #3ead47, #56bd41, #70cd38, #8bdc2b, #a8eb12)', borderTopRightRadius: '20px', borderBottomRightRadius: '20px'} : {}}>
                  <SpanLink image="interacciones.png"></SpanLink>
                  <span>Interacciones</span>
                </BoxLink>
              </Link>
            </li>
          }
          {(role === "superadmin" || verifiedMenu("analiticas", user.id)) &&
            <li>
              <Link href="/admin/analytical" passHref>
                <BoxLink href="Analytical" onClick={handleclick} style={namePage === 'analytical' ? {background: 'linear-gradient(to right top, #3ead47, #56bd41, #70cd38, #8bdc2b, #a8eb12)', borderTopRightRadius: '20px', borderBottomRightRadius: '20px'} : {}}>
                  <SpanLink image="analitico.png"></SpanLink>
                  <span>Analiticas</span>
                </BoxLink>
              </Link>
            </li>
          }
          {(role === "superadmin" || verifiedMenu("blog", user.id)) &&
            <li>
              <Link href="/admin/blog" passHref>
                <BoxLink href="Blog" onClick={handleclick} style={namePage === 'blog' ? {background: 'linear-gradient(to right top, #3ead47, #56bd41, #70cd38, #8bdc2b, #a8eb12)', borderTopRightRadius: '20px', borderBottomRightRadius: '20px'} : {}}>
                  <SpanLink image="blog.png"></SpanLink>
                  <span>Blog</span>
                </BoxLink>
              </Link>
            </li>
          }
          {
            (role === "superadmin" || verifiedMenu("forum", user.id)) &&
              <li>
                <Link href="/admin/forum" passHref>
                  <BoxLink href="Forum" style={namePage === 'forum' ? {background: 'linear-gradient(to right top, #3ead47, #56bd41, #70cd38, #8bdc2b, #a8eb12)', borderTopRightRadius: '20px', borderBottomRightRadius: '20px'} : {}}>
                    <SpanLink image="chat.png"></SpanLink>
                    <span>Foro</span>
                    {pendingComments > 0 ? <BadgeBox>{pendingComments}</BadgeBox> : null}
                  </BoxLink>
                </Link>
              </li>
          }
          {(role === "superadmin" || verifiedMenu("edicion", user.id)) &&
            <li>
              <Link href="/admin/edit" passHref>
                <BoxLink href="Edit" onClick={handleclick} style={namePage === 'edit' ? {background: 'linear-gradient(to right top, #3ead47, #56bd41, #70cd38, #8bdc2b, #a8eb12)', borderTopRightRadius: '20px', borderBottomRightRadius: '20px'} : {}}>
                  <SpanLink image="pencil.png"></SpanLink>
                  <span>Edicion</span>
                </BoxLink>
              </Link>
            </li>
          }
          <li style={{ height: "80px" }}></li>
          <li style={{ height: "80px" }}></li>
          <li style={{ height: "80px" }}></li>
          <li style={{ height: "80px" }}></li>
        </BoxUl>
      </BoxMenu>
    </Box>
  );
};

export default SideNav;
